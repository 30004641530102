
.navbar {
  display: flex;
  justify-content: space-between;
  
  padding: 10px 20px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
 
  position: fixed;
  
  background-color:var(--our-service-content-background-color);
  align-items: center;
}

.navbar a {
  color: var(--navbar-font-color);

}
.navbar_logo {
  width: 211px;
  height: 62px;
}

.logo {
  font-size: 24px;
  width: 211px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.menu {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.menu.open {


  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: var(--our-service-content-background-color);
  background:var(--bakcground-color);  

  margin-top: -3px;

}

.menu li {
  margin-right: 40px;
}

.menu li a {
  color: var(--navbar-font-color);
  font-size: 20px;
  text-decoration: none;
}
.menu.open li a{
  color:var(--our-service-content-background-color);
  font-size: 20px;
  text-decoration: none;
}
.menu li a:hover {
  color:var(--navbar-menu-list-hover);
  font-size: 20px;
  text-decoration: none;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  width: 50px;
  height: 50px;
  cursor: pointer;
  gap: 6px;
  margin-top: 12px;
  
}

.menu-toggle span {
  background-color: var(--navbar-font-color);
  height: 3px;
  width: 100%;
  display: block;
  margin-top: 3px;
  
}

.navbar .menu li {
  position: relative;
}

.navbar .menu li .line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1.0px;
  transition: width 0.4s ease-in-out;
}

.navbar .menu li:hover .line {
  width: 100%;
  margin-top: 19px;
  border: 1px solid var(--navbar-font-color);
}

.line {
  margin-top: 10px;
}

.navbar .menu li:nth-child(1) .line {
  background-color: var(--navbar-font-color);
  margin-top: 5px;
}

.navbar .menu li:nth-child(2) .line {
  background-color: var(--navbar-font-color);
}

.navbar .menu li:nth-child(3) .line {
  background-color: var(--navbar-font-color);
}

.navbar .menu li:nth-child(4) .line {
  background-color: var(--navbar-font-color);
}

.navbar .menu li:nth-child(5) .line {
  background-color: var(--navbar-font-color);
}

.navbar .menu li:nth-child(6) .line {
  background-color: var(--navbar-font-color);
}

.navbar .menu li:nth-child(7) .line {
  background-color: var(--navbar-font-color);
}

.Navbar_Tags {
  font-size: 16px;
  line-height: 23px;
  align-items: center;
}

.lower-content {
  transition: transform 0.3s ease;
}

.lower-content.open {
  transform: translateY(100%);
}

.spacer {
  height: 280px;
}

.menu-toggle.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(2px, 2px);
}

.menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(2px, -2px);
}

/* Media Query for responsiveness */
@media (max-width: 1124px)  {
  .navbar .menu li {
    margin-top: 30px;
  }
  .menu {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    transition: all 0.3s ease;
  }
  .menu.open {
    display: flex;
    height: 300px;
    padding: 0px 12px;
    background:var(--background-color)
  }
  .menu-toggle {
    display: flex;
  }
  .navbar {
    padding: 0px;
  }
  .menu li {
    margin-top: 20px;
  }
  .menu li a {
    color: var(--our-service-content-background-color);
    font-size: 20px;
    text-decoration: none;
    color: var(--navbar-font-color);
    text-align: center;
  }
  .menu li a:hover {
    color: var(--navbar-menu-list-hove);
    font-size: 20px;
    text-decoration: none;
  }
  .menu ul li {
    margin-top: 20px;
  }
  .logo {
    font-size: 24px;
    width: 212px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
                                 

  }
  .navbar_logo {
    width: 60%;
    height: auto;

    margin-left: 7%;
  }
  .Navbar_Tags{
    color: white;
  }
}

