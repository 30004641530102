.aboutus_container{
    height:100vh;
    background: var(--background-color);
   
    display: flex;
    justify-content: center;
    align-items: center;

}
.aboutus_inner_container{
  
    width:60%;
    margin:auto;
   height: auto;
}




.aboutus{
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    line-height: 72px;
    text-align: left;
    color:#ACC2EE
    
}
.aboutus_context_description{
    font-family: Lato;
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color:#CEE7FE

}
.aboutus_our_team_description{
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color:#ACC2EE;
    margin-top: 20px;
    height: 70px;
}
.about_us_know_more{
    width: 250px;
    height: 60px;
    gap: 0px;
    border: 1px 0px 0px 0px;
  
    border: 1px solid #ACC2EE;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-radius 0.7s ease;
    cursor: pointer;
    margin-top: 20px;
    color:#ACC2EE;
    margin-top: 20px;
    
}
.about_us_know_more_text {
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center; /* Text alignment declared once */
    color: #ACC2EE;
 
}



@media screen and (max-width:1124px) and (min-width:768px) {
    .aboutus_inner_container{
        width: 90%;
        margin: auto;
        height: auto;
    }
    .aboutus_context_description{
        font-family: Lato;
        font-size: 2.0rem;
        font-weight: 400;
        line-height: 3rem;
        text-align: left;
        color:#CEE7FE
    
    }
    .about_us_know_more{
        width: 60%;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        border: 1px solid #ACC2EE;
        opacity: 0px;margin-top: 20px;
        
    }
    .about_us_know_more_text {
        font-family: Lato;
        font-size: 1.6rem;
        font-weight: 400;
        line-height:1rem;
        text-align: center; /* Text alignment declared once */
        color: #ACC2EE;
        transition: border-radius 0.3s ease;
    }
    .aboutus_our_team_description{
        font-size: 1.4rem;
        line-height:2rem;
    }
    .about_us_know_more{
        margin-top: 20px;
        width: 200px;
        height: 40px;
    }
    .about_us_know_more_text {
        font-family: Lato;
        font-size: 1rem;
        font-weight: 400;
        line-height: 28.8px;
        text-align: center; /* Text alignment declared once */
        color: #ACC2EE;
     
    }
    
}
@media screen and (max-width:767px) and (min-width:320px) {
    .aboutus_container{
        padding:120px 0px ;
        height: 100vh;
    }
    .aboutus_inner_container{
        width:90%;
        margin:auto;
        height: auto;
        
    }
    
    .aboutus{
        font-family: Lato;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 21.6px;
        
    }
    
    .aboutus_context_description{
        font-family: Lato;
        font-size: 2rem;
        font-weight: 400;
        line-height:42px;
        text-align: left;
        color:#CEE7FE;
        margin-top: 16px;
    
    }
    .aboutus_our_team_description{
        font-size: 1rem;
        line-height:18.91px;
        color:#ACC2EE;
        font-family: Banumans;
        font-weight: 400;
    }
   
    .about_us_know_more{
        width: 160px;
        height: 0px;
        padding: 20px 30px;
    }

    
    .about_us_know_more_text{
        font-size: 16px;
    }

}
@media screen and (max-width:320px){

    .aboutus_context_description{
        font-family: Lato;
        font-size: 22px;
        font-weight: 400;
        line-height: 40px;
        text-align: left;
        color:#CEE7FE;
      
    
    }
    .aboutus_inner_container{
  
        width:90%;
        margin:auto;
        
       
    }
    
    .aboutus_our_team_description{
        font-size: 19px;
        line-height: 32px;
    }
    .about_us_know_more{
        width: 160px;
        height: 0px;
        padding: 20px 30px;
    }
    .aboutus_container{
        padding: 50px 10px;
    }
    .about_us_know_more_text{
        font-size: 16px;
    }

}
