* {
    margin: 0;
    padding: 0;
}

.about_container {

    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(90deg, #203B60 0%, #4C648B 100%);
    display: flex;
}

.about1 {
    width: 60vw;
    margin-bottom: 15vw;
}

.heading {
    font-size: 1vw;
    line-height: 6.4vh;
    color: #E0E0E0;
    font-family: Lato;
    font-family: 400;
    font-family: Lato, sans-serif;
}

.about-description {
    font-size: 2vw;
    line-height: 2.5vw;
    color: #CEE7FE;
    font-family: Lato, sans-serif;
    font-family: 400;

}

.about-detail {
    font-size: 1vw;
    line-height: 1.8vw;
    margin-top: 2.85vh;
    font-family: Lato, sans-serif;
    font-family: 400;
    color: #ACC2EE;
}


.owner {
    position: absolute;
    top: 53%;
    height: 100vh;
    width: 100%;

}

.owner-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 70%;
    gap: 30px;
    margin: auto;
}


.owner-card {
    width: 15vw;
    height: 23.5vw;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
}

.owner-img {
    height: 17vw;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    object-position: top;
}

.teammember {
    text-align: center;
    padding: 0.3em;
    font-size: 1.2vw;
    color: #4D658D;
}

.owner-details {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.75vw;
}

.linkedin-img {
    width: 1.2vw;
    height: 1.2vw;
}

.about2 {
    margin-top: 85vh;
    padding: 1.4vw 0px;
}

.employee {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    margin: auto;
    justify-content: space-between;
    gap: 30px 0px;
    height: auto;
}

.designation {
    color: rgba(30, 58, 95, 1);
    letter-spacing: 0.1px;

}

.owner-card:nth-child(1) .teammember,
.owner-card:nth-child(2) .teammember,
.owner-card:nth-child(3) .teammember,
.owner-card:nth-child(1) .designation,
.owner-card:nth-child(2) .designation,
.owner-card:nth-child(3) .designation {
    color: #ffff;
}


@media screen and (max-width:1535px) and (min-width:1280px) {
    .about1 {
        width: 60vw;
        margin-bottom: 20vw;
    }
    .about-detail {
        font-size: 1.1vw;
        line-height: 2.5vh;
        margin-top: 2vh;
        font-family: Lato, sans-serif;
        font-family: 400;
        color: #ACC2EE;
    }

    .owner {
        top: 52%
    }

    .designation {
        font-size: 1vw;
    }

    .linkedin-img {
        width: 1vw;
        height: 1vw;
    }

    .about2 {
        margin-top: calc(65vw - 25vw);
        padding: 1.4vw 0px;
    }
}

@media screen and (max-width :1279px) and (min-width:1024px) {
    .about1 {
        width: 60vw;
        margin-bottom: 20vw;
    }

    .heading {
        font-size: 1.3vw;
        line-height: 4vh;
        color: #E0E0E0;
        font-family: Lato;
        font-family: 400;
    }

    .about-detail {
        font-size: 1.5vw;
        line-height: calc(4vh-.9vw);
        margin-top: 2vh;
        font-family: Lato, sans-serif;
        font-family: 400;
        color: #ACC2EE;
    }

    .owner-card {
        width: 20vw;
        height: 25vw;

    }

    .owner-card:nth-child(3) .teammember {
        color: #4D658D;
    }

    .owner-card:nth-child(3) .designation {
        color: rgba(30, 58, 95, 1);
    }

    .owner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .owner-img {
        height: 20vw;
        object-fit: cover;
    }

    .linkedin-img {
        width: 1vw;
        height: 1vw;
    }

    .employee {
        grid-template-columns: repeat(2, 1fr);
    }

    .designation , .teammember {
        font-size: 1.2vw;
    }

    .about2 {
        margin-top: 140vh;
    }
}

@media screen and (max-width :1023px) and (min-width:768px) {
    .about_container {
        height: 100vh;
    }

    .about1 {
        width: 60vw;
        margin-bottom: 40vw;
    }

    .heading {
        font-size: 1.5vw;
        line-height: 6.4vh;
        color: #E0E0E0;
        font-family: Lato;
        font-family: 400;
    }

    .about-description {
        font-size: 3vw;
        line-height:5vw;
        color: #CEE7FE;
        font-family: Lato, sans-serif;
        font-family: 400;

    }

    .about-detail {
        font-size: 1.5vw;
        line-height: 2vw;
        margin-top: 2vw;
        font-family: Lato, sans-serif;
        font-family: 400;
        color: #ACC2EE;
    }


    .owner {
        top: 52%
    }

    .owner-card {
        width: 25vw;
        height: 34vw;

    }

    .owner-card:nth-child(3) .teammember {
        color: #4D658D;
    }

    .owner-card:nth-child(3) .designation {
        color: rgba(30, 58, 95, 1);
    }

    .owner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .owner-img {
        height: 27vw;
    }

    .linkedin-img {
        width: 1vw;
        height: 1vw;
    }

    .employee {
        grid-template-columns: repeat(2, 1fr);
    }

    .designation {
        font-size: 1.5vw;
    }

    .about2 {
        margin-top: 90vw;
    }
}

@media screen and (max-width:767px) and (min-width:640px) {
    .about_container {
        height: 95vh;
    }

    .about1 {
        width: 60vw;
        margin-bottom: 35vw;
    }

    .heading {
        font-size: 1.5vw;
        line-height: 6.4vh;
        color: #E0E0E0;
        font-family: Lato;
        font-family: 400;
    }

    .about-description {
        font-size: 3vw;
        line-height: 4vh;
        color: #CEE7FE;
        font-family: Lato, sans-serif;
        font-family: 400;

    }

    .about-detail {
        font-size: 1.5vw;
        line-height: 2.5vh;
        margin-top: 2.85vh;
        font-family: Lato, sans-serif;
        font-family: 400;
        color: #ACC2EE;
    }

    .owner {
        top: 55%;
    }

    .owner-card {
        width: 25vw;
        height: 34vw;

    }

    .owner-card:nth-child(3) .teammember {
        color: #4D658D;
    }

    .owner-card:nth-child(3) .designation {
        color: rgba(30, 58, 95, 1);
    }

    .owner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .owner-img {
        height: 27vw;
    }

    .linkedin-img {
        width: 1.5vw;
        height: 1.5vw;
    }

    .employee {
        grid-template-columns: repeat(2, 1fr);
    }

    .designation , .teammember {
        font-size: 1.8vw;
    }

    /* .about2{
        margin-top:90vw;
    } */
}

@media screen and (max-width:639px)  {
    .about_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75vh;
        width: 100%;
        background: linear-gradient(90deg, #203B60 0%, #4C648B 100%);
        display: flex;
    }

    .about1 {
        width: 80vw;
        margin-top: 10vw;
    }

    .heading {
        font-size: 3vw;
        line-height: 3vh;
    }

    .about-description {
        font-size: 5.5vw;
        line-height:calc(3.5vh);
    }

    .about-detail {
        font-size: 3vw;
        line-height: calc(2.5vh);
        margin-top: 2vh;
    }

    .owner {
        top: 60%;
    }

    .owner-card {
        width: 60vw;
        height: 60vw;

    }

    .owner-card:nth-child(3) .teammember,
    .owner-card:nth-child(2) .teammember,
    .owner-card:nth-child(1) .teammember {
        color: #4D658D;
    }

    .owner-card:nth-child(3) .designation, .owner-card:nth-child(2) .designation, .owner-card:nth-child(1) .designation {
        color: rgba(30, 58, 95, 1);
    }

    .owner-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 15vw;
    }

    .owner-img {

        height: 100%;
    }

    .linkedin-img {
        width: 3vw;
        height: 3vw;
    }

    .employee {
        grid-template-columns: repeat(1, 1fr);
        gap: 15vw
    }

    .teammember {
        font-size: 3.5vw;
    }

    .designation {
        font-size: 3vw;
    }

    .about2 {
        margin-top: 355vw;
    }

    .footer_container {
        margin-top: 15vw;
    }
}
@media screen and (max-width:470px){
    .heading {
        font-size: 3vw;
        line-height: 3vh;
    }

    .about-description {
        font-size: 5vw;
        line-height:calc(3vh-.9vw);
    }

    .about-detail {
        font-size:3vw;
        line-height: calc(2vh-.9vw);
        margin-top: 2vh;
    }
}