*{
    margin: 0px;
    padding: 0px;
    border: 0px;
}
.home{
    position: relative;
}
.scroller_container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 90vh;
    
    width: 100vw;
   
  }
  .aniWrap {
    
    display: block;
    width: 28px;
  
  }
  
  .mouse {
    width: 24px;
    height: 40px;
    border: 3px solid  rgb(169,169,169);
    border-radius: 14px;
  }
  
  .scroller {
    width: 6px;
    height: 6px;
    border-radius: 2px;
    background:linear-gradient(90deg, rgb(32, 59, 96) 0%, rgb(76, 100, 139) 50%, rgb(169, 169, 169) 100%);
    position: relative;
    top: 10px;
    left: 6px;
    animation: scrolls 1.3s ease-out infinite ; /* Chrome, Safari, Opera */
  }

  .scroller_container.hidden {
    display: none;
}
@keyframes scrolls {
    0%   {top: 18px; opacity: 1; height: 4px}
    95% {top: 5px; opacity: 0; height: 8px}
  	100% {top: 18px; opacity: 1; height: 4px}
}



/* Change background color between 200vh and 400vh */
