.name_of_website{
    font-size: 1.2rem;
    line-height: 40px;
    text-align: center;
 
    margin-top: -20px;
}
.App{
    scroll-behavior: smooth;
}
@media screen and (max-width:768px){
    .name_of_website{
        text-align: center; 
        font-size:1rem ;
        margin: 0px;
    }
}

