.case_study_mainContainer {
    padding: 50px 10px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Add this line */
}


