
*{
  margin: 0px;
  padding: 0px;
}
.introduction_container{
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 50px 10px;
    height:100vh;
    align-items: center;
    

   
}
.introduction_inner_container{
  width:60%;
  padding: 10px 0px 10px 20px;
  
  margin: 0px;
 
  

}
.introduction_img{
  width:40%;
  height: auto;

}
.introduction_img img{
  width:100%;
 
}
.powering_transformation_with {
    font-size: 3.75em;
    font-weight: 400;
    text-align: left;
    font-family: "Lato", sans-serif;
    font-style: normal;
    color:var(--introduction-heading-color);
  }
  .artificial_intelligence {
    font-size: 5.6rem;
    font-weight: 400;
    text-align: left;
    font-family: "Lato", sans-serif;
    font-style: normal;
    line-height: 120px;
    color:var(--introduction-sub-heading);
    width: 100%; 
  }
  .Introduction_description {
    font-family: Lato, sans-serif;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color:var(--navbar-font-color);
    height: 50px; 
  }
  .introduction_contact_us{
  width: 300px;
  height: 60px;
  gap: 0px;
  border: 1px 0px 0px 0px;
 
  border:1px solid var( --case-study-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-radius 0.7s ease;
  cursor: pointer;
  margin-top: 20px;

  }
 .introduction_contact_us_text{
  font-family: Lato,sans-serif;
text-align: center;
font-size: 24px;
line-height: 28.8;
font-weight: 400;
 }
/* .introduction_contact_us:hover{
    border-radius: 72px 0px 72px 0px;
} */


@media screen and (max-width: 1124px) and (min-width: 768px) {
  .introduction_container {
      
      flex-direction: column-reverse;
      align-items: center; /* Center items horizontally */
      padding: 50px 0px;
      justify-content: center;
      height: auto;
      
      }




  .introduction_inner_container {
      width: 90%;
      text-align: center; /* Center text */
     
      
     
  }

  .introduction_img {
      width: 60%; /* Adjust image width */
      text-align: left;
      height: auto;
   
     
      
     
  }
  .introduction_img img{
    width:100%;
    
  }

  .powering_transformation_with{
    font-size: 40px;
    line-height: 1.5em;
    margin-top: 20px;
   
  }
  .Introduction_description{
    /* text-align: center; */
    font-family: Baumans,"sans-sarif";
    
    line-height: 1.2em;
    margin-top: 20px;
    
  }
  .artificial_intelligence{
    /* text-align: center; */
    margin-top: 1%;
    font-size: 45px;
    line-height: 1.5em;
  }
  .introduction_contact_us {
   
   
   margin-top: 3%;
   width: 60%;
   height: 90px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid black;
 

    
  }
  .introduction_contact_us_text{
    font-size: 1.5em;
  }

}

@media screen and (max-width:767px) and (min-width:320px) {
    .introduction_container{
        margin: auto;
        flex-direction: column-reverse;
        height: auto;
       }
     
       .introduction_img{
        width:100%;
        
      }
     .introduction_inner_container{
      width: 100%;
      padding: 0px;
     }
    .powering_transformation_with {
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        font-family: "Lato", sans-serif;
        font-style: normal;
        color:var(--case-study-heading-color);
        margin: auto;
         width: 95%;
        }
      
      .artificial_intelligence {
       font-size: 2.0rem;
       width: 95%;
       margin: auto;
       letter-spacing: 1.2px;
       margin-top: 10px;
       font-family: Banumas;
       color: var(  --case-study-content-color);
       padding: 0px;
       line-height: 30px; 
      }
      
      .Introduction_description {
        font-family: Baumans!important;
        font-size: 1em;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-top: 16px!important; /* Add margin bottom to create space between text */
        color:#0F1C2E;
        width:95%;
        margin: auto;   
      }
      .introduction_contact_us{
        width: 160px;
        height: 35px;
        margin-left: 10px;
        gap: 0px;
        border: 1px 0px 0px 0px;
        color:#0F1C2E
      }
      .introduction_contact_us_text{
        text-align: center;
        font-size: 16px;
        line-height: 28.8;
         }
   
}
@media screen and (max-width:320px) {
    .introduction_container{
        margin: auto;
        flex-direction: column-reverse;
        justify-content: center;
        
        padding: 65px 0px;
       }
     
       .introduction_img{
        width:100%;
        margin-top: 0px;
        
        
       
        
        
      }
    
      
     .introduction_inner_container{
      
      width: 100%;
      padding: 0px;
     }
    .powering_transformation_with {
        font-size: 1.25em;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        font-family: "Lato", sans-serif;
        font-style: normal;
        color:#4D658D;
        margin: auto;
         
         width: 95%;
         
        }
      
      .artificial_intelligence {
      font-size: 1.5em;
      line-height: 40.8;
       width: 95%;
       margin: auto;
       letter-spacing: 1.2px;
       margin-top: 10px;
       font-family: Banumas;
       color: #1E3A5F;
       padding: 0px;
       line-height: 30px;
        
      }
      
      .Introduction_description {
        font-family: Baumans!important;
        font-size: 1em;
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        letter-spacing: 0px;
        margin-top: 16px!important; /* Add margin bottom to create space between text */
        color:#0F1C2E;
        width:95%;
        margin: auto;
       
        
      }
      .introduction_contact_us{
        width: 160px;
        height: 30px;
        margin-left: 10px;
        gap: 0px;
        border: 1px 0px 0px 0px;
      
        color:#0F1C2E
      }
      .introduction_contact_us_text{
        text-align: center;
        font-size: 16px;
        line-height: 28.8;
         }
 
    
}
