.not-found-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}



